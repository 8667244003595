import {Button, Container, Divider, FormHelperText, Grid, MenuItem, Select, Typography} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const LTLQuoteViewDetails = () => {

    const {quoteId} = useParams();
    const history = useHistory();

    const [quoteInfo, setQuoteInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [quoteStatus, setQuoteStatus] = useState('default');

    const getQuoteDetails = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingLTLRatingHistory/getLTLCacheRatingHistoryById`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    ratingHistoryId: quoteId
                }
            })
            console.log('[LTLQuoteViewDetails] getQuoteDetails api - result', result);
            setQuoteInfo(result?.data);
            setQuoteStatus(result?.data?.status);

        } catch (e) {
            console.log('[LTLQuoteViewDetails] getQuoteDetails api - error', e?.response);
        } finally {
            setLoading(false);
        }
    }

    const handleEditStatus = async (status) => {
        let requestURL = `${NODE_ROUTE_URI}/shippingLTLRatingHistory/editLTLCacheRatingHistoryById`;
        try {
            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: {
                    ratingHistoryId: quoteId,
                    status: status
                }
            })
            console.log('[LTLQuoteViewDetails] handleEditStatus api - result', result);

        } catch (e) {
            console.log('[LTLQuoteViewDetails] handleEditStatus api - error', e?.response);
        } finally {
            setLoading(false);
        }
    }

    const handleChangeStatus = (event) => {
        setQuoteStatus(event?.target?.value);
        handleEditStatus(event?.target?.value);
    }

    useEffect(() => {
        if (quoteId) {
            getQuoteDetails();
        }
    }, [quoteId]);

    // console.log('[LTLQuoteViewDetails] quoteId', quoteId);
    // console.log('[LTLQuoteViewDetails] quoteInfo', quoteInfo);

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    LTL Quote Details
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            {
                                quoteInfo?.icon_uri ?
                                    <img src={`${LABEL_DOWNLOAD_URL_PREFIX}${quoteInfo?.icon_uri}`} alt="logo"
                                         width='60px'/>
                                    :
                                    <Fragment>
                                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                            Carrier
                                        </Typography>
                                        <Typography style={{fontSize: '18px'}}>
                                            {quoteInfo?.account_base}
                                        </Typography>
                                    </Fragment>
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Expired Date
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {dayjs(quoteInfo?.expired_time).format("YYYY-MM-DD")}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Quote Number
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {quoteInfo?.quote_no || 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Quote Status
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    value={quoteStatus}
                                    size='small'
                                    onChange={handleChangeStatus}
                                >
                                    <MenuItem disabled value='default'>
                                        <em>Select Status</em>
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        <Typography style={{color: '#1D8B45', fontSize: '18px'}}>
                                            Active
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value={-1}>
                                        <Typography style={{color: '#454545', fontSize: '18px'}}>
                                            Inactive
                                        </Typography>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography style={{fontSize: '20px', fontWeight: '600', textAlign: 'left'}}>
                                Ship From: <span style={{
                                fontWeight: '300',
                                fontSize: '18px'
                            }}>{quoteInfo?.ship_from_city}, {quoteInfo?.ship_from_province}, {quoteInfo?.ship_from_country}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography style={{fontSize: '20px', fontWeight: '600', textAlign: 'left'}}>
                                Ship To: <span style={{
                                fontWeight: '300',
                                fontSize: '18px'
                            }}>{quoteInfo?.ship_to_city}, {quoteInfo?.ship_to_province}, {quoteInfo?.ship_to_country}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography style={{fontSize: '20px', fontWeight: '600', textAlign: 'left'}}>
                                FTL: <span style={{
                                fontWeight: '300',
                                fontSize: '18px'
                            }}>{quoteInfo?.ftl_count}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography style={{fontSize: '20px', fontWeight: '600', textAlign: 'left'}}>
                                Total Quantity: <span style={{
                                fontWeight: '300',
                                fontSize: '18px'
                            }}>{quoteInfo?.quantity}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography style={{fontSize: '20px', fontWeight: '600', textAlign: 'left'}}>
                                Total Weight: <span style={{
                                fontWeight: '300',
                                fontSize: '18px'
                            }}>{quoteInfo?.total_weight_lb} LB</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography style={{fontSize: '20px', fontWeight: '600', textAlign: 'left'}}>
                                Total Volume Weight: <span style={{
                                fontWeight: '300',
                                fontSize: '18px'
                            }}>{quoteInfo?.total_volume_weight_lb} LB</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '20px', fontWeight: '600', textAlign: 'left'}}>
                                Pallet List
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography style={{fontSize: '18px'}}>
                                        No.
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontSize: '18px'}}>
                                        Length
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontSize: '18px'}}>
                                        Width
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontSize: '18px'}}>
                                        Height
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontSize: '18px'}}>
                                        Weight
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontSize: '18px'}}>
                                        Quantity
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                {
                                    quoteInfo?.packages?.map((each, index) => (
                                        <Fragment key={index}>
                                            <Grid item xs={2}>
                                                <Typography style={{fontSize: '18px'}}>
                                                    {index + 1}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{fontSize: '18px'}}>
                                                    {each?.originalLength || each?.length} {each?.originalLengthUnit || each?.lengthUnit}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{fontSize: '18px'}}>
                                                    {each?.originalWidth || each?.width} {each?.originalLengthUnit || each?.lengthUnit}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{fontSize: '18px'}}>
                                                    {each?.originalHeight || each?.height} {each?.originalLengthUnit || each?.lengthUnit}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{fontSize: '18px'}}>
                                                    {each?.originalWeight || each?.weight} {each?.originalWeightUnit || each?.weightUnit}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{fontSize: '18px'}}>
                                                    {each?.quantity}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                        </Fragment>
                                    ))
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '20px', fontWeight: '600', textAlign: 'left'}}>
                                Cost
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        Tariff Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        $ {quoteInfo?.tariff_charge?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        Fuel Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        $ {quoteInfo?.fuel_charge?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        Tax
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        $ {quoteInfo?.taxes?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        Cross Border Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        $ {quoteInfo?.cross_border_fee?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        Total
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        $ {quoteInfo?.final_amount?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '20px', fontWeight: '600', textAlign: 'left'}}>
                                Remark Notes: <span style={{
                                fontWeight: '300',
                                fontSize: '18px'
                            }}>{quoteInfo?.remark_notes || 'N/A'}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{marginBottom: '20px'}}>
                    <Button
                        variant='contained'
                        onClick={() => history.push(('/v2/ltl-quote-list?type=list'))}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            Back to Quote List
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}