import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Divider, Grid} from "@mui/material";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import {useEffect, useState} from "react";

export const PackageOrderTransactionPickupEach = ({appointment, key, handleUpdatePickup}) => {

    const storedToken = getAccessToken("access_token");

    const [invoiceNumber, setInvoiceNumber] = useState('');

    const getInvoiceNumber = async (invoiceId) => {

        if (!invoiceId) {
            return 'N/A'
        }

        const requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/getInvoiceInfoByInvoiceId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: invoiceId
                }
            })
            console.log('result', result);
            const {data: {invoiceRefNumber}} = result;
            return invoiceRefNumber;
        } catch (e) {
            console.log('e', e?.response);
            return 'N/A'
        }
    }

    useEffect(() => {
        (async () => {
            if (appointment?.crm_invoice_no) {
                const crmInvoiceNumber = await getInvoiceNumber(appointment?.crm_invoice_no);
                setInvoiceNumber(crmInvoiceNumber);
            }
        })()
    }, [appointment?.crm_invoice_no])

    return (
        <Grid container spacing={1} key={key}>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Appointment Id
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.appoint_id}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Confirmation #
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.confirm_appoint_no || 'N/A'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Invoice #
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {invoiceNumber || 'N/A'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Appointment Date
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.appoint_date}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Pickup Time
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.appoint_time_start?.split(':').slice(1).join(':')} - {appointment?.appoint_time_end?.split(':').slice(1).join(':')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Pickup Location
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_location}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Pickup Address
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_addr_line2 && `${appointment?.pickup_addr_line2}-`}{appointment?.pickup_addr}
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_city}, {appointment?.pickup_province}
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_country}, {appointment?.pickup_postal_code}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Contact Information
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_name}
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_tel}
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_email}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Tracking #
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.tracking_nos}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Remark Notes
                    </Typography>
                    <Typography style={{
                        textAlign: 'left',
                        fontSize: '14px',
                    }}>
                        {appointment?.remark_notes || 'N/A'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Status
                    </Typography>
                    <Typography style={{
                        textAlign: 'left',
                        fontSize: '14px',
                        color: +appointment?.status === 1 ? '#1D8B45' : +appointment?.status === 0 ? '#454545' : +appointment?.status === -1 ? 'error.main' : '#454545'
                    }}>
                        {+appointment?.status === 1 ? 'Submitted' : +appointment?.status === 0 ? 'Initiated' : +appointment?.status === -1 ? 'Canceled' : 'Inactive'}
                    </Typography>
                </Box>
            </Grid>
            {
                (+appointment?.status === 0 || +appointment?.status === 100) &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={() => handleUpdatePickup(appointment)}
                            size='small'
                        >
                            <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                Update
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            }
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Grid>

    )
}