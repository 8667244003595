import {Button, Container, FormLabel, Grid, InputAdornment, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {carManufactures, extractFullAddress} from "../../utils/Helper";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MovingCategoryListTable} from "../MovingCategoryList/MovingCategoryListTable";
import {Pagination} from "@mui/lab";
import {CarQuoteTable} from "./CarQuoteTable";
import {useHistory} from "react-router-dom";
import {PAGE_SIZE} from "../../utils/constInfo";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        },
    }
});

export const CarQuoteList = () => {

    const classes = useStyles();
    const history = useHistory();

    const [searchString, setSearchString] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [quoteData, setQuoteData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);
    const [shipFrom, setShipFrom] = useState({
        city: null,
        province: null,
        postalCode: null,
        country: null
    });
    const [shipTo, setShipTo] = useState({
        city: null,
        province: null,
        postalCode: null,
        country: null
    });
    const [carMake, setCarMake] = useState(null);

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const handleAddQuote = () => {
        history.push('/v2/car-quote-list/add');
    }

    const handleSearchQuote = () => {
        getQuoteList();
    }

    const handleSearchQuoteByKeyDown = (event) => {
        if (event.key === 'Enter') {
            getQuoteList();
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;

            return predictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const getQuoteList = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/movingCar/getAllMovingCarQuotes`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: searchString || '',
                    page: 0,
                    pageSize: PAGE_SIZE,
                    shipFromCountry: shipFrom?.country?.code,
                    shipFromProvince: shipFrom?.province?.code,
                    shipFromCity: shipFrom?.city,
                    shipToCountry: shipTo?.country?.code,
                    shipToProvince: shipTo?.province?.code,
                    shipToCity: shipTo?.city,
                    make: carMake
                }
            })
            console.log('[LTLQuoteList] - getQuoteList result', result);
            setCurrentPage(result?.data?.pagination?.currentPage);
            setLastPage(result?.data?.pagination?.lastPage);
            setQuoteData(result?.data?.data);
            setError(false);
        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getQuoteListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/movingCar/getAllMovingCarQuotes`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: searchString || '',
                    page: page,
                    pageSize: PAGE_SIZE,
                    shipFromCountry: shipFrom?.country?.code,
                    shipFromProvince: shipFrom?.province?.code,
                    shipFromCity: shipFrom?.city,
                    shipToCountry: shipTo?.country?.code,
                    shipToProvince: shipTo?.province?.code,
                    shipToCity: shipTo?.city,
                    make: carMake
                }
            });
            console.log('[LTLQuoteList] - getQuoteListByPage result', result);
            setQuoteData(result?.data?.data);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const handleInputShipFromCity = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            city: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality');
        console.log('[handleInputShipFromCity] predictions', predictions);
        setMovingFromAddressPredictions(predictions);
    }

    const handleInputShipToCity = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            city: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality');
        setMovingToAddressPredictions(predictions);
    }

    const handleChangeShipFromCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.place_id);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship from', address);
            setShipFrom(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: {
                    name: address?.state?.name,
                    code: address?.state?.code,
                },
                country: {
                    code: address?.country?.code,
                    name: address?.country?.name,

                }
            }))
        }
    }

    const handleChangeShipToCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.place_id);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship to', address);
            setShipTo(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: {
                    name: address?.state?.name,
                    code: address?.state?.code,
                },
                country: {
                    code: address?.country?.code,
                    name: address?.country?.name,
                }
            }))
        }
    }

    const handleChangeCarMake = async (event, value) => {
        setCarMake(value);
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value;
        getQuoteListByPage(page - 1);
    };

    useEffect(() => {
        (async () => {
            await getQuoteList();
        })()
    }, []);

    console.log('[CarQuoteList] move from', shipFrom);
    console.log('[CarQuoteList] move to', shipTo);
    console.log('[CarQuoteList] carMake', carMake);
    console.log('[CarQuoteList] search string', searchString);

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Car Quote List
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={4}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}>
                                <FormControl fullWidth>
                                    <FormLabel>
                                        <Typography style={{textAlign: 'left'}}>
                                            Moving From City
                                        </Typography>
                                    </FormLabel>
                                    <Autocomplete
                                        options={movingFromAddressPredictions}
                                        getOptionLabel={option => option?.description}
                                        onInputChange={handleInputShipFromCity}
                                        onChange={handleChangeShipFromCity}
                                        fullWidth
                                        PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='outlined'
                                            className={classes.smallInput}
                                            // placeholder={'Moving To City'}
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '14px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option.description}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}>
                                <FormControl fullWidth>
                                    <FormLabel>
                                        <Typography style={{textAlign: 'left'}}>
                                            Moving To City
                                        </Typography>
                                    </FormLabel>
                                    <Autocomplete
                                        options={movingToAddressPredictions}
                                        getOptionLabel={option => option?.description}
                                        onInputChange={handleInputShipToCity}
                                        onChange={handleChangeShipToCity}
                                        fullWidth
                                        PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='outlined'
                                            className={classes.smallInput}
                                            // placeholder={'Moving To City'}
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '14px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option.description}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}>
                                <FormControl fullWidth>
                                    <FormLabel>
                                        <Typography style={{textAlign: 'left'}}>
                                            Car Make
                                        </Typography>
                                    </FormLabel>
                                    <Autocomplete
                                        options={carManufactures}
                                        // getOptionLabel={option}
                                        // onInputChange={handleInputShipToCity}
                                        onChange={handleChangeCarMake}
                                        fullWidth
                                        PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='outlined'
                                            className={classes.smallInput}
                                            // placeholder={'Car Make'}
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '14px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item md={10} xs={8}>
                            <TextField
                                value={searchString}
                                fullWidth
                                size='small'
                                onInput={handleSearchString}
                                onKeyDown={handleSearchQuoteByKeyDown}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleSearchQuote}
                                            >
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                classes={{root: classes.customTextField}}
                                // label='Order# / REF# / Tracking# / Name / Email / Address / Phone'
                                placeholder='Car Name / Car Model / City'
                            />
                        </Grid>
                        <Grid item md={2} xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-end'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    onClick={handleAddQuote}
                                >
                                    <Typography
                                        style={{textTransform: 'none', fontSize: '16px'}}
                                    >
                                        Add quote
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    {
                        loading ?
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress/>
                            </Box>
                            :
                            error ?
                                <Box sx={styles.ShippingOrderList}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        Sorry it currently encounter some issues, please try again later
                                    </Typography>
                                </Box>
                                :
                                quoteData?.length === 0 ?
                                    <Box sx={styles.ShippingOrderList}>
                                        <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                            No result
                                        </Typography>
                                    </Box>
                                    :
                                    <Box sx={styles.ShippingOrderList}>
                                        <CarQuoteTable
                                            quote={quoteData}
                                        />
                                        <Pagination
                                            count={lastPage}
                                            page={+currentPage}
                                            size='small'
                                            onChange={handlePageChange}
                                        />
                                    </Box>

                    }
                </Box>
            </Box>
        </Container>
    )
}