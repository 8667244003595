import Box from "@mui/material/Box";
import {Divider, Grid, Typography} from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import dayjs from "dayjs";
import {useEffect, useState, Fragment} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {PackageOrderTransactionEach} from "./PackageOrderTransactionEach";

const style = {
    PackageOrderDashboardTransactionRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        background: '#FFFFFF'
    }
}

export const PackageOrderTransactionList = ({
                                                transactionList,
                                                selectTransactionData,
                                                orderInfo,
                                                cbHandleSelectedTransactionData
                                            }) => {

    console.log('[PackageOrderTransactionList] orderInfo', orderInfo);
    console.log('[PackageOrderTransactionList] transactionList', transactionList);
    console.log('[PackageOrderTransactionList] selectTransactionData', selectTransactionData);

    const handleSelectTransaction = (transactionData) => {
        cbHandleSelectedTransactionData(transactionData);
    }

    return (
        <Box sx={style.PackageOrderDashboardTransactionRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Shipping Transactions
                        </Typography>
                        {
                            +selectTransactionData?.is_return_label === 1 && <Box sx={{display: 'flex'}}>
                                <RotateLeftIcon sx={{color: '#1D8B45'}}/>
                                <Typography sx={{color: '#1D8B45'}}>Return Label</Typography>
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    height: '100%',
                                    gap: '5px',
                                }}>
                                    <Typography><span
                                        style={{fontWeight: '600'}}>Order Date:</span>
                                    </Typography>
                                    <Typography>
                                        {dayjs(selectTransactionData?.created_at)?.format('YYYY-MM-DD')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    height: '100%',
                                    gap: '5px',
                                }}>
                                    <Typography style={{fontWeight: '600'}}>
                                        Carrier:
                                    </Typography>
                                    <Typography>
                                        {selectTransactionData?.account_base?.toUpperCase()}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Ship From
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderInfo?.send_from_name}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderInfo?.send_from_addr}, {orderInfo?.send_from_city}, {orderInfo?.send_from_province}, {orderInfo?.send_from_postal_code}, {orderInfo?.send_from_country}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderInfo?.send_from_tel}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                    Transaction Id
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                    Shipping To
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{textAlign: 'center', fontWeight: '600'}}>
                                    Charge
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{textAlign: 'center', fontWeight: '600'}}>
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            {
                                transactionList?.map((transaction, index) => (
                                    <Fragment key={index}>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                value="start"
                                                control={<Checkbox
                                                    checked={transaction?.tran_id === selectTransactionData?.tran_id}
                                                    onChange={() => handleSelectTransaction(transaction)}
                                                    // disabled={confirm}
                                                    icon={<RadioButtonUncheckedIcon/>}
                                                    checkedIcon={<CheckCircleIcon/>}
                                                    color="success"
                                                />}
                                                label={
                                                    <Typography>
                                                        {transaction?.tran_id}
                                                    </Typography>
                                                }
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {transaction?.send_to_name}
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {transaction?.send_to_addr}, {transaction?.send_to_city}, {transaction?.send_to_province}, {transaction?.send_to_postal_code}, {transaction?.send_to_country}
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {transaction?.send_to_tel}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography style={{textAlign: 'center'}}>
                                                $ {(+transaction?.final_total).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography style={{
                                                textAlign: 'center',
                                                color: +transaction?.tran_status !== -1 ? '#1D8B45' : '#FF0303'
                                            }}>
                                                {(+transaction?.tran_status !== -1) ? 'Active' : 'Inactive'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {
                                            transaction?.tran_id === selectTransactionData?.tran_id &&
                                            <Grid item xs={12}>
                                                <PackageOrderTransactionEach
                                                    selectTransactionData={selectTransactionData}/>
                                            </Grid>
                                        }
                                    </Fragment>
                                ))
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}