import {Fragment, useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

export const LTLOrderListSpecialService = ({
                                               getDataFromChildComponent,
                                               triggerAction,
                                               isConfirm,
                                               shipperResidential,
                                               consigneeResidential
                                           }) => {

    const [specialService, setSpecialService] = useState([]);

    const addOrRemoveSpecialService = (serviceName) => {
        const hasServiceCode = specialService?.some(service => service.serviceCode === serviceName);

        if (hasServiceCode) {
            // Remove the element with serviceCode 'serviceName'
            const updatedServices = specialService?.filter(service => service.serviceCode !== serviceName);
            setSpecialService(updatedServices);
        } else {
            // Add the element with serviceCode 'serviceName'
            const updatedServices = [...specialService, {serviceCode: serviceName}];
            setSpecialService(updatedServices);
        }
    }

    const addOrRemoveSpecialServices = (serviceNameParent, serviceNameChild) => {
        const hasParentServiceCode = specialService.some(service => service.serviceCode === serviceNameParent);
        const hasChildServiceCode = specialService.some(service => service.serviceCode === serviceNameChild);
        if (!hasParentServiceCode && !hasChildServiceCode) {
            const updatedServices = [...specialService, {serviceCode: serviceNameParent}, {serviceCode: serviceNameChild}];
            setSpecialService(updatedServices);
        } else if (!hasParentServiceCode && hasChildServiceCode) {
            const updatedServices = [...specialService, {serviceCode: serviceNameParent}];
            setSpecialService(updatedServices);
        } else {
            const updatedServices = specialService?.filter(service => service.serviceCode !== serviceNameParent);
            setSpecialService(updatedServices);
        }
    }

    const handleAppointmentDelivery = () => {
        addOrRemoveSpecialService('APTFGT');
    }

    const handleProtectedService = () => {
        addOrRemoveSpecialService('PROTEC');
    }

    const handlePrivateResidenceDelivery = () => {
        addOrRemoveSpecialServices('PRESDL', 'TLGDEL');
    }

    const handlePrivateResidencePickup = () => {
        addOrRemoveSpecialServices('PRESPU', 'TLGPU');
    }

    const handleTailgateDelivery = () => {
        addOrRemoveSpecialService('TLGDEL');
    }

    const handleTailgatePickup = () => {
        addOrRemoveSpecialService('TLGPU');
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({specialService});
        }
    }, [triggerAction])

    useEffect(() => {
        const hasServiceCode = specialService?.some(service => service.serviceCode === 'PRESPU');
        if (shipperResidential) {
            if (!hasServiceCode) {
                // Add the element with serviceCode 'serviceName'
                const updatedServices = [...specialService, {serviceCode: 'PRESPU'}];
                setSpecialService(updatedServices);
            }
        } else {
            if (hasServiceCode) {
                // Remove the element with serviceCode 'serviceName'
                const updatedServices = specialService.filter(service => service.serviceCode !== 'PRESPU');
                setSpecialService(updatedServices);
            }
        }
    }, [shipperResidential])

    useEffect(() => {
        const hasServiceCode = specialService?.some(service => service.serviceCode === 'PRESDL');
        if (consigneeResidential) {
            if (!hasServiceCode) {
                // Add the element with serviceCode 'serviceName'
                const updatedServices = [...specialService, {serviceCode: 'PRESDL'}];
                setSpecialService(updatedServices);
            }
        } else {
            if (hasServiceCode) {
                // Remove the element with serviceCode 'serviceName'
                const updatedServices = specialService.filter(service => service.serviceCode !== 'PRESDL');
                setSpecialService(updatedServices);
            }
        }
    }, [consigneeResidential])

    console.log('[LTLOrderListSpecialService] special service', specialService);

    return (
        <Fragment>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "PRESPU")}
                            onChange={handlePrivateResidencePickup}
                        />}
                        label={
                            <Typography>
                                Private Residence Pickup
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "TLGPU")}
                            onChange={handleTailgatePickup}
                        />}
                        label={
                            <Typography>
                                Tailgate Pickup
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "PROTEC")}
                            onChange={handleProtectedService}
                        />}
                        label={
                            <Typography>
                                Protected Service
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "PRESDL")}
                            onChange={handlePrivateResidenceDelivery}
                        />}
                        label={
                            <Typography>
                                Private Residence Delivery
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "TLGDEL")}
                            onChange={handleTailgateDelivery}
                        />}
                        label={
                            <Typography>
                                Tailgate Delivery
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService?.some(service => service?.serviceCode === "APTFGT")}
                            onChange={handleAppointmentDelivery}
                        />}
                        label={
                            <Typography>
                                Appointment Delivery
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
        </Fragment>
    )
}