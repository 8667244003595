import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
} from "@material-ui/core";
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, Controller } from "react-hook-form";
import TransactionAdjustmentTable from "./TransactionAdjustmentTable";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, Pagination } from "@material-ui/lab";
import axios from "axios";
import { LABEL_DOWNLOAD_URL_PREFIX, SERVICE_SERVICE_URL } from "../../utils/apiUrl";
import { useSelector } from "react-redux";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import dayjs from "dayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "react-datepicker/dist/react-datepicker.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TransactionInvoicesTable from "./TransactionInvoicesTable";
import TransactionUnTrackingInvoicesTable from "./TransactionUnTrackingInvoicesTable";
import Upload from '@mui/icons-material/Upload';
import RetrieveUpdatedInvoice from "./RetrieveUpdatedInvoice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%"
  },
  unTrackingTable: {
    "border-spacing": "2rem 1rem"
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
    "white-space": "nowrap"
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const TransactionAdjustment = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");


  // const partnerInfo = getPartnerInfo();
  const partnerInfo = useSelector((state) => state.user);

  const { register, handleSubmit, control } = useForm();
  const [shippingListData, setShippingListData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [untrackingInvoiceList, setUnTrackingInvoiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputCSVFile = useRef(null);
  const [csvFileObj, setCsvFileObj] = useState(null);
  const [csvFileName, setCsvFileName] = useState("");

  const inputPDFFile = useRef(null);
  const [pdfFileObj, setPdfFileObj] = useState(null);
  const [pdfFileName, setPDFFileName] = useState("");

  const [amount, setAmount] = useState(0);
  const [amountGross, setAmountGross] = useState(0);
  const [tax, setTax] = useState(0);


  const [taxRate, setTaxRate] = useState(12);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(null);

  const [toastOpen, setToastOpen] = useState(false);
  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const getUntrackingTransactionInvoiceList = async () => {
    try {
      setUnTrackingInvoiceList([]);
      const { data } = await axios.get(`${SERVICE_SERVICE_URL}/transactionAdjustments/untrackingTransactionInvoiceList`);
      for (const unTrackinginvoice of data) {
        unTrackinginvoice["newTrackingNumber"] = unTrackinginvoice.tracking_no;
      }
      console.log(data);
      setUnTrackingInvoiceList(data);
    } catch (e) {
      console.log(e);
    }

  }

  useEffect(() => {
    try {
      getUntrackingTransactionInvoiceList();
    } catch (e) {
      console.log(e);
    }
  }, [])

  // const getData = async ({ order_id, product_id, ref_other_no, account_base, cargo_control_no, send_to_name, send_to_tel, send_to_addr, currentPage }) => {
  //     const { data } = await axios.get(`${PARTNER_URI}/shipping/list?account_base=${account_base}&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`);
  //     const result = data.result;
  //     setShippingListData(result);
  //     try {
  //     } catch (e) {
  //       console.log(e);
  //       setShippingListData([]);
  //     } finally {
  //       setIsLoading(false);
  //     }
  // }
  const getData = async ({ account_base, taxRate, accountNumber, amountGross, amount, invoiceNumber }) => {
    try {
      if (!csvFileObj) {
        return;
      }
      setIsLoading(true);
      const formData = new FormData();
      formData.append("csvFile", csvFileObj);
      formData.append("pdfFile", pdfFileObj);
      formData.append("accountBase", account_base);
      formData.append("taxRate", taxRate / 100);
      formData.append("accountNumber", accountNumber);
      formData.append("invoiceNumber", invoiceNumber);
      formData.append("amountGross", amountGross);
      formData.append("amount", amount);
      formData.append("invoiceDate", invoiceDate.toDate());

      const { data } = await axios.post(`${SERVICE_SERVICE_URL}/transactionAdjustments`, formData, {
        headers: {
          "Content-Type": "multipart/form-data;",
          "X-Requested-With": "XMLHttpRequest",
        }
      })
      console.log(data.result);
      setShippingListData(data.result);
      setErrorMessage("");
      setSuccessMessage("Transaction CSV upload success!");
      handleToastClick();
      await getUntrackingTransactionInvoiceList();
    } catch (e) {
      console.log(e);
      setErrorMessage("Error! This CSV file is invalid! Either row errors or you already submit this before!");
      handleToastClick();
    } finally {
      setIsLoading(false);
    }
  }

  const saveUnTrackingInvoiceList = async (e) => {
    try {
      e.preventDefault();
      // console.log(untrackingInvoiceList);
      const requestBody = {
        unTrackingInvoiceList: untrackingInvoiceList.map(v => {
          return {
              adjustmentId: v.id,
              newTrackingNumber: v.newTrackingNumber
          }
        })
      }
      console.log(requestBody);
      const { data } = await axios.post(`${SERVICE_SERVICE_URL}/transactionAdjustments/updateUnTrackingTransactionInvoiceList`, requestBody);
      console.log(data);
      setSuccessMessage("Save Tracking Number Success!");
      handleToastClick();
      await getUntrackingTransactionInvoiceList();
    } catch (e) {
      console.log(e);
    }
  }

  const onSubmit = data => {
    // console.log(data);
    const { account_base, accountNumber, invoiceNumber } = data;

    if (+amountGross > +amount) {
      setErrorMessage("Amount Gross and Amount are invalid!");
      handleToastClick();
      return;
    }

    if (+taxRate < 0 || +taxRate > 15) {
      setErrorMessage("Tax Rate is invalid!");
      handleToastClick();
      return;
    }

    if (invoiceDate === null) {
      setErrorMessage("Please Select Invoice Date!");
      handleToastClick();
      return;
    }

    if (!account_base || account_base === "") {
      setErrorMessage("Please Select the Account Base!");
      handleToastClick();
      return;
    }

    setErrorMessage("");
    getData({ account_base, taxRate, accountNumber, invoiceNumber, amountGross, amount });
  }

  const getInvoices = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`${SERVICE_SERVICE_URL}/transactionAdjustments/invoices`);
      setInvoiceData(data);
      setErrorMessage("");
    } catch (e) {
      setErrorMessage(e?.error || "Error!");
      handleToastClick();
    }
  }

  const onCsvDrop = (e) => {
    if (!(e.target.files && e.target.files[0])) {
      return;
    }
    setCsvFileObj(e.target.files[0]);
    setCsvFileName(e.target.files[0].name);
  };

  const onPDFDrop = (e) => {
    if (!(e.target.files && e.target.files[0])) {
      return;
    }
    setPdfFileObj(e.target.files[0]);
    setPDFFileName(e.target.files[0].name);
  };

  useEffect(() => {
    if (amount !== 0 && amountGross !== 0) {
      console.log(amount);
      console.log(amountGross);
      // (net - gross) / gross
      const calculateRate = (((+amount - +amountGross) / +amountGross) * 100).toFixed(2);
      setTaxRate(calculateRate);
      const calculateTax = (+amount - +amountGross);
      setTax(calculateTax);
    }
  }, [amount, amountGross])

  // useEffect(() => {
  //   if (tax !== 0 && amountGross !== 0 && amount === 0) {
  //     const calculateAmount = +amountGross + +tax;
  //     setAmount(calculateAmount);
  //   }
  // }, [amount, amountGross, tax]);

  return (
      <Box paddingBottom="3rem">
        {/*Pop-up Toast*/}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={toastOpen}
          onClose={handleToastClose}
          autoHideDuration={3000}
          message="Submit Transaction">
          {(() => {
            if (errorMessage !== "") {
              return (
                <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                  Error!
                  <hr />
                  {errorMessage}
                </Alert>
              )
            }
            return (
              <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                {successMessage}
              </Alert>
            )
          })()}

        </Snackbar>
        <Box>
          <Box component="form" width={"1000px"} margin="0 auto" sx={{
            display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
            alignItems: "center", alignContent: "center"
          }}>
            <h1>Transaction Adjustment</h1>
            <Box flex="45%" width="100%">
              <TextField id="outlined-basic" label="Invoice Number" variant="outlined" {...register("invoiceNumber")} fullWidth />
            </Box>
            <Box flex="45%" width="100%" >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Invoice Date"
                  value={invoiceDate}
                  onChange={setInvoiceDate}
                  renderInput={(params) => <TextField {...params} id="outlined-basic" label="Invoice Date" variant="outlined" {...register("invoiceDate")} fullWidth />}
                />
              </LocalizationProvider>
            </Box>
            <Box flex="45%" width="100%">
              <TextField id="outlined-basic" label="Account Number (Optional)" variant="outlined" {...register("accountNumber")} fullWidth />
            </Box>
            <Box flex="45%" width="100%">
              <TextField id="outlined-basic" label="Amount Gross" variant="outlined" fullWidth type="number" error={+amountGross > +amount}  value={amountGross} onChange={(e) => {
                setAmountGross(e.target.value);
              }}
                         InputProps={{
                           inputProps: {
                             min: 0 }}}
              />
            </Box>
            <Box flex="45%" width="100%">
              <TextField id="outlined-basic" label="Amount Net" variant="outlined" value={amount}
                         error={+amountGross > +amount}
                         onChange={(e) => {
                setAmount(e.target.value);
              }}
                         InputProps={{
                           inputProps: {
                             min: 0 }}}
                         fullWidth type="number" />
            </Box>
            <Box flex="45%" width="100%">
              <TextField id="outlined-basic" label="Tax" variant="outlined" fullWidth type="number" error={+amountGross > +amount}  value={tax} onChange={(e) => {
                setTax(e.target.value);
              }}
                         InputProps={{
                           inputProps: {
                             min: 0 }}}
              />
            </Box>
            <Box flex="45%" width="100%">
              <TextField id="outlined-basic" label="Tax Rate" variant="outlined" type="number" value={taxRate} onChange={v => {
                setTaxRate(v.target.value);
              }}
                         InputProps={{
                           inputProps: {
                             max: 15,
                             min: 0
                           }
                         }} defaultValue={12} fullWidth error={+taxRate > 15 || +taxRate < 0} />
              <span>Tax Rate should be from 0% to 15%</span>
            </Box>
            <Box display="flex" flexWrap="wrap" sx={{ gap: "2rem" }}>
              <input type="file" style={{ display: "none" }} ref={inputCSVFile} onChange={onCsvDrop} accept=".csv" name="file" />
              <input type="file" style={{ display: "none" }} ref={inputPDFFile} onChange={onPDFDrop} accept=".pdf" name="file" />
              <Button
                variant="contained"
                component="span"
                color="primary"
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                }}
                onClick={() => {
                  inputCSVFile.current.click();
                }}
                endIcon={<Upload />}
              >
                Upload CSV
              </Button>
              <Button
                variant="contained"
                component="span"
                color="primary"
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                }}
                onClick={() => {
                  inputPDFFile.current.click();
                }}
                endIcon={<Upload />}
              >
                Upload PDF
              </Button>
            </Box>
            {pdfFileName && (
              <Box className={classes.buttonFilesWrapper} mt={3}>
                <Box display="flex" alignItems="center">
                  <InsertDriveFileIcon />
                  {pdfFileName}
                </Box>
              </Box>
            )}
            {csvFileName && (
              <Box className={classes.buttonFilesWrapper} mt={3}>
                <Box display="flex" alignItems="center">
                  <InsertDriveFileIcon />
                  {csvFileName}
                </Box>
              </Box>
            )}

            <Box mt={2} mb={4}>
              <FormControl>
                <FormLabel required color="primary">Account Base</FormLabel>
                <Controller control={control} name="account_base" rules={{ required: true }} render={({ field }) => (
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        {...field}
                    >
                      <FormControlLabel value="FedEx" control={<Radio />} label="FedEx" />
                      <FormControlLabel value="UPS" control={<Radio />} label="UPS" />
                      <FormControlLabel value="Canpar" control={<Radio />} label="Canpar" />
                      <FormControlLabel value="CanadaPost" control={<Radio />} label="Canada Post" />
                    </RadioGroup>
                )}/>

              </FormControl>
              {errorMessage !== "" &&
                <Alert severity="error">
                  {errorMessage}
                </Alert>}
            </Box>
            <Box display="flex" sx={{ gap: "3rem" }}>
              <LoadingButton
                  variant="contained"
                  component="span"
                  color="primary"
                  style={{
                    textTransform: "unset",
                    fontSize: "1rem",
                    width: "7rem",
                  }}
                  loading={isLoading}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
          <hr style={{ marginBlock: "3rem" }} />


          <Box component="form" width={"1000px"} margin="0 auto" sx={{
            display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
            alignItems: "center", alignContent: "center"
          }}>
            <h1>UnTracking Invoice Transactions</h1>
            <Box>
              <Box>
                <Box mt={2}>
                  <table className={classes.unTrackingTable}>
                    <thead>
                    <tr>
                      <th className={classes.idTH}>Invoice Id</th>
                      <th className={classes.idTH}>Invoice Number</th>
                      <th className={classes.idTH}>Carrier</th>
                      <th className={classes.idTH}>Amount (excl. tax)</th>
                      <th className={classes.idTH}>Origin Tracking Number</th>
                      <th className={classes.idTH}>New Tracking Number</th>
                    </tr>
                    </thead>
                    <tbody className={classes.tbody}>
                      {untrackingInvoiceList.map((untrackingInvoice, index) => (
                        <TransactionUnTrackingInvoicesTable invoiceId={untrackingInvoice.id}
                                                            invoiceNumber={untrackingInvoice.invoice_no}
                                                            trackingNumber={untrackingInvoice.tracking_no}
                                                            setUnTrackingInvoiceList={setUnTrackingInvoiceList}
                                                            index={index}
                                                            key={index}
                                                            grossAmount={untrackingInvoice.amount_gross}
                                                            carrier={untrackingInvoice.account_base}
                        />
                      ))}
                    </tbody>
                  </table>
                  <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    style={{
                      textTransform: "unset",
                      fontSize: "1rem",
                      width: "7rem",
                    }}
                    type="button"
                    onClick={saveUnTrackingInvoiceList}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <hr style={{ marginBlock: "3rem" }} />
          <Button
            variant="contained"
            component="span"
            color="primary"
            style={{
              textTransform: "unset",
              fontSize: "1rem",
              width: "12rem",
              marginBottom: "2rem"
            }}
            type="button"
            onClick={getInvoices}
          >
            Search Invoices
          </Button>
          {
            isLoading ? (
                <>
                <Box>
                  <h1>Loading...</h1>
                </Box>
                </>
            ) : (
                <>
                  <Box>
                    <Box>
                      <Box mt={2}>
                        <table className={classes.table}>
                          <thead>
                          <tr>
                            {/*<th className={classes.idTH}>Transaction ID</th>*/}
                            {/*<th className={classes.idTH}>Partner ID</th>*/}
                            {/*<th className={classes.idTH}>Order ID</th>*/}
                            {/*<th className={classes.nameTH}>Ref Order Number</th>*/}
                            {/*<th className={classes.nameTH}>Ref Product ID</th>*/}
                            {/*<th className={classes.nameTH}>Ref Other Number</th>*/}
                            {/*<th className={classes.nameTH}>Send To Name/Email/Tel</th>*/}
                            {/*<th className={classes.nameTH}>Send To Address/City/Province/Country/Postal Code</th>*/}
                            {/*<th className={classes.nameTH}>Account Base</th>*/}
                            {/*<th className={classes.nameTH}>Gross Price</th>*/}
                            {/*<th className={classes.nameTH}>Net Price</th>*/}
                            {/*<th className={classes.nameTH}>Labels</th>*/}
                            {/*<th className={classes.nameTH}>Date</th>*/}

                            <th className={classes.idTH}>Invoice ID</th>
                            <th className={classes.idTH}>Invoice Number</th>
                            <th className={classes.idTH}>Account Base</th>
                            <th className={classes.idTH}>Account Number</th>
                            <th className={classes.idTH}>CSV Invoice</th>
                            <th className={classes.idTH}>PDF Invoice</th>
                            <th className={classes.idTH}>Gross Amount / Amount</th>
                            {/*<th className={classes.idTH}>Amount</th>*/}
                            <th className={classes.idTH}>Tax Rate</th>
                            <th className={classes.idTH}>Status</th>
                            <th className={classes.idTH}>Invoice Date</th>
                          </tr>
                          </thead>
                          <tbody className={classes.tbody}>
                          {invoiceData.map((v, index) => {
                            return <TransactionInvoicesTable key={index}
                                                             accountNumber={v.account_number}
                                                             invoiceId={v.id}
                                                             invoiceDate={v.invoice_date}
                                                             invoiceNumber={v.invoice_no}
                                                             taxRate={v.tax_rate}
                                                             grossAmount={v.amount_gross}
                                                             amount={v.amount}
                                                             status={v.status}
                                                             accountBase={v.account_base}
                                                             invoiceFileURI={`https://storage.googleapis.com/${v.csv_invoice_file_uri}`}
                                                             pdfFileURI={v?.pdf_invoice_file_uri ? `https://storage.googleapis.com/${v.pdf_invoice_file_uri}` : ""}
                            />;
                          })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                  </Box>
                </>
            )
          }
          <hr style={{ marginBlock: "3rem" }} />
          <RetrieveUpdatedInvoice />
        </Box>
      </Box>
  );
};

export default TransactionAdjustment;
