import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function TransactionInvoicesTable({ invoiceId,
                                                   accountBase,
                                                   accountNumber,
                                                   invoiceNumber,
                                                   invoiceFileURI,
                                                   grossAmount,
                                                   amount,
                                                   taxRate,
                                                   status,
                                                   invoiceDate,
                                                   pdfFileURI
}) {
  const classes = useStyles();

  const getStatusText = (statusCode) => {
    if (statusCode === 0) {
      return "Initial"
    }
    return "Processed";
  }

  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>{invoiceId}</td>
        <td>{invoiceNumber}</td>
        <td>{accountBase}</td>
        <td>{accountNumber}</td>
        <td>
          <a href={invoiceFileURI} target="_blank" rel="noopener noreferrer">
            {invoiceFileURI ? "Download" : ""}
          </a>
        </td>
        <td>
          <a href={pdfFileURI} target="_blank" rel="noopener noreferrer" >
            {pdfFileURI !== "" ? "View" : ""}
          </a>
        </td>
        <td>
          <CurrencyFormat value={grossAmount} displayType={'text'} thousandSeparator={true} prefix={`$`} />
          <br />
          <CurrencyFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={`$`} />
        </td>
        {/*<td>*/}
        {/*  <CurrencyFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={`$`} />*/}
        {/*</td>*/}
        <td>{taxRate * 100}%</td>
        <td>{getStatusText(status)}</td>
        <td>{invoiceDate}</td>
      </tr>
    </>
  );
}
