import {Fragment} from "react";
import {Button, Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";
import {useHistory} from "react-router-dom";

export const CarQuoteListEach = ({quote_info}) => {

    const history = useHistory();

    console.log('[CarQuoteListEach] quote_info', quote_info);

    return (
        <Fragment>
            <Grid item md={3} xs={3}>
                <Grid container spacing={1}>
                    <Grid item md={4} xs={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            // justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{textAlign: 'left'}}>
                                {quote_info?.make}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            // justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{textAlign: 'left'}}>
                                {quote_info?.model}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{textAlign: 'center'}}>
                                {quote_info?.year_of_production}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={6} xs={6}>
                <Grid container spacing={1}>
                    <Grid item md={4} xs={4}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            // justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{textAlign: 'left'}}>
                                {quote_info?.ship_from_city}, {quote_info?.ship_from_province}, {quote_info?.ship_from_country}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={4}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            // justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{textAlign: 'left'}}>
                                {quote_info?.ship_to_city}, {quote_info?.ship_to_province}, {quote_info?.ship_to_country}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={2} xs={2}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            // justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{textAlign: 'left'}}>
                                $ {(+quote_info?.final_amount)?.toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={2} xs={2}>
                        {
                            quote_info?.icon_uri ?
                                <img src={`${LABEL_DOWNLOAD_URL_PREFIX}${quote_info?.icon_uri}`} alt="logo"
                                     width='35px'/>
                                :
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                    height: '100%'
                                }}>
                                    <Typography>
                                        {quote_info?.account_base}
                                    </Typography>
                                </Box>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={3} xs={3}>
                <Grid container spacing={1}>
                    <Grid item md={6} xs={6}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{textAlign: 'center'}}>
                                {quote_info?.shipping_days_range}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{
                                textAlign: 'center',
                                color: (+quote_info?.status) === 1 ? '#1D8B45' : '#454545'
                            }}>
                                {(+quote_info?.status) === 1 ? 'Active' : 'Inactive'}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={3}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={() => history.push(`/v2/car-quote-list/${quote_info?.id}`)}
                            >
                                <Typography style={{
                                    fontSize: '14px',
                                    textTransform: 'none',
                                    color: '#1D8B45'
                                }}>
                                    Details
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )
}