import React, {useState, useEffect} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import logo from "../images/logo.png";
import {ReactComponent as OceanShippingIcon} from "../images/earth.svg";
import {
    AppBar,
    IconButton,
    Toolbar,
    MenuItem,
    Menu,
    Divider,
    Typography,
    makeStyles,
    Box,
    useMediaQuery,
} from "@material-ui/core";
import "../styles.css";
import actions from "./Action";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@material-ui/core/List";
import {Button, ListItem} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import {styled, useTheme} from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ListAltIcon from "@material-ui/icons/ListAlt";
import {CloudUploadOutlined} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import BusinessIcon from "@mui/icons-material/Business";
import HandshakeIcon from "@mui/icons-material/Handshake";
import InventoryIcon from "@mui/icons-material/Inventory";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {deleteToken} from "../utils/doToken";
import action from "./Action";
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import BuildIcon from '@mui/icons-material/Build';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


const MenuItems = [
    {
        icon: <LocalShippingIcon/>,
        text: 'Transaction Adjustment',
        location: "/v2/transaction/adjustment",

    },
    {
        icon: <ListAltIcon/>,
        text: 'Create/UPdate Route Price',
        location: "/v2/routePrice"
    },
    {
        icon: <CloudUploadOutlined/>,
        text: 'Upload PNG Label',
        location: "/v2/uploadLabel"
    },
    {
        icon: <BusinessIcon/>,
        text: 'Company List',
        location: "/v2/companylist"
    },
    {
        icon: <HandshakeIcon/>,
        text: 'Partner List',
        location: "/v2/partnerList"
    },
    {
        icon: <InventoryIcon/>,
        text: 'Not Payed Backend Orders',
        location: "/v2/orderpayment/backendorder/notpaid/list"
    },
    {
        icon: <InventoryIcon/>,
        text: 'All Backend Orders',
        location: "/v2/orderpayment/backendorder/all/list"
    },
    {
        icon: <InventoryIcon/>,
        text: 'Not Paid Shipping Orders',
        location: "/v2/orderpayment/shippingorder/notpaid/list"
    },
    {
        icon: <InventoryIcon/>,
        text: 'All Shipping Orders',
        location: "/v2/orderpayment/shippingorder/all/list"
    },
    {
        icon: <MarkEmailReadIcon/>,
        text: 'Process End-user Etransfers',
        location: "/v2/etransferemailList"
    },
    {
        icon: <SearchIcon/>,
        text: 'Search All Transactions',
        location: "/v2/searchtransactions"
    },
    {
        icon: <LocationSearchingIcon/>,
        text: 'Tracking Package',
        location: "/v2/tracking"
    },
    {
        icon: <LocationSearchingIcon/>,
        text: "e-Transfer Top-Up",
        location: "/v2/email/topUp"
    },
    {
        icon: <AccountCircleIcon/>,
        text: "End User List",
        location: "/v2/end-user-list"
    },
    {
        icon: <ListAltIcon/>,
        text: "LTL Order List",
        location: "/v2/ltl-order-list"
    },
    {
        icon: <ListAltIcon/>,
        text: "LTL Quote",
        location: "/v2/ltl-quote-list"
    },
    {
        icon: <ListAltIcon/>,
        text: "Category List",
        location: "/v2/moving-category-list"
    },
    {
        icon: <ListAltIcon/>,
        text: "Car Quote List",
        location: "/v2/car-quote-list"
    },
    {
        icon: <ListAltIcon/>,
        text: "Package Order List",
        location: "/v2/package-orders"
    },
    {
        icon: <FileUploadIcon/>,
        text: "Upload File",
        location: "/v2/upload-file"
    },
    {
        icon: <ListAltIcon/>,
        text: "Moving Order List",
        location: "/v2/moving-order-list"
    },
    {
        icon: <DirectionsBoatIcon/>,
        text: "Container Shipping",
        location: "/v2/container-shipping-list"
    },
    {
        icon: <BuildIcon/>,
        text: "Tools",
        location: "/v2/tools"
    },
    {
        icon: <ManageAccountsIcon/>,
        text: "Account",
        location: "/v2/account"
    },
]
const drawerWidth = 240;

const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    button: {
        borderRadius: 20,
        borderColor: "green",
        color: "green",
        fontSize: "14pt",
        flexGrow: 1,
    },
    userButton: {
        borderRadius: 20,
        borderColor: "green",
        color: "green",
        fontSize: "14pt",
        marginRight: "2vw",
    },
    hideGoBackArrow: {
        display: "none",
    },
    displayGoBackArrow: {
        display: "block",
    },
}));

const MenuBar = ({deferredPrompt, setDeferredPrompt}) => {

    const adminInfo = useSelector(state => state?.adminReducer?.adminInfo);
    const [open, setOpen] = React.useState(true);
    const {height, width} = useWindowDimensions();

    const theme = useTheme()

    const dispatch = useDispatch()

    useEffect(() => {
        width < 1980 ? handleDrawerClose() : handleDrawerOpen();
    }, [width])

    useEffect(() => {
        open ? dispatch(actions.menuBarAction.navigateBarToggle(300)) : dispatch(actions.menuBarAction.navigateBarToggle(0))
    }, [open])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogoClick = () => {
        setOpen(false);
        history.replace("/v2")
    }

    const history = useHistory();

    const handleSignOut = () => {
        deleteToken("access_token");
        dispatch(action?.adminAction?.saveAdminInfo(null));
        history.push("/v2/sign-in");
    }

    return (
        <>
            <AppBar
                position="fixed"
                className="AppBar"
                style={{background: "white", display: "flex", bottom: "auto", top: "0", margin: "0"}}
            >
                <Toolbar style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <IconButton
                        color="#000000"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        disabled={!adminInfo}
                        edge="start"
                        sx={{mr: 2, ...(open && {display: 'none'})}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    {
                        adminInfo &&
                        <Box sx={{
                            display: 'flex',
                            mr: 4
                        }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleSignOut}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    Sign Out
                                </Typography>
                            </Button>
                        </Box>
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleLogoClick}>
                        <img src={logo} alt="logo" width="120px"/>
                    </IconButton>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    {MenuItems.map((item, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton onClick={() => {
                                history.replace(item.location);
                                setOpen(false);
                            }}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.text}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider/>
            </Drawer>
        </>
    );
};

export default MenuBar;
