import {Divider, Grid, Typography} from "@mui/material";
import {Fragment} from "react";
import {MovingCategoryListEach} from "../MovingCategoryList/MovingCategoryListEach";
import {CarQuoteListEach} from "./CarQuoteListEach";

export const CarQuoteTable = ({quote}) => {

    return (
        <Grid container spacing={1}>
            <Grid item md={3} xs={3}>
                <Grid container spacing={1}>
                    <Grid item md={4} xs={3}>
                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                            Make
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={3}>
                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                            Model
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={3}>
                        <Typography style={{fontWeight: '600'}}>
                            Year
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={6} xs={5}>
                <Grid container spacing={1}>
                    <Grid item md={4} xs={6}>
                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                            Moving From
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                            Moving To
                        </Typography>
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                            Cost
                        </Typography>
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Typography style={{fontWeight: '600'}}>
                            Carrier
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={3} xs={4}>
                <Grid container spacing={1}>
                    <Grid item md={6} xs={3}>
                        <Typography style={{fontWeight: '600'}}>
                            Shipping Day
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={3}>
                        <Typography style={{fontWeight: '600'}}>
                            Status
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={3}>
                        <Typography style={{fontWeight: '600'}}>
                            Action
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                quote?.map((quoteEach, index) =>
                    <Fragment key={index}>
                        <CarQuoteListEach quote_info={quoteEach}/>
                    </Fragment>
                )
            }
        </Grid>

    )
}

