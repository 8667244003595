import {Divider, Grid, Typography} from "@mui/material";
import {Fragment} from "react";
import {LTLQuoteListEach} from "./LTLQuoteListEach";

export const LTLQuoteListTable = ({quote}) => {

    console.log('[LTLQuoteListTable] quote', quote);

    return (
        <Grid container spacing={1}>
            <Grid item md={4} xs={5}>
                <Grid container spacing={1}>
                    <Grid item md={6} xs={6}>
                        <Typography style={{fontWeight: '600'}}>
                            Shipping From
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={6}>
                        <Typography style={{fontWeight: '600'}}>
                            Shipping To
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={3} xs={3}>
                <Grid container spacing={1}>
                    <Grid item md={4} xs={2}>
                        <Typography style={{fontWeight: '600'}}>
                            Carrier
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={2}>
                        <Typography style={{fontWeight: '600'}}>
                            Cost
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={2}>
                        <Typography style={{fontWeight: '600'}}>
                            Total Weight / Quantity
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/*<Grid item md={2} xs={2}>*/}
            {/*    <Grid item md={1} xs={1}>*/}
            {/*        <Typography style={{fontWeight: '600'}}>*/}
            {/*            Total Quantity*/}
            {/*        </Typography>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
            <Grid item md={5} xs={5}>
                <Grid container spacing={1}>

                    <Grid item md={4} xs={4}>
                        <Typography style={{fontWeight: '600'}}>
                            Quote Number
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={3}>
                        <Typography style={{fontWeight: '600'}}>
                            Expired Date
                        </Typography>
                    </Grid>
                    <Grid item md={2} xs={2}>
                        <Typography style={{fontWeight: '600'}}>
                            Status
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={3}>
                        <Typography style={{fontWeight: '600'}}>
                            Action
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                quote?.map((quoteEach, index) =>
                    <Fragment key={index}>
                        <LTLQuoteListEach quoteInfo={quoteEach}/>
                    </Fragment>
                )
            }
        </Grid>
    )
}