import {Fragment} from "react";
import {Button, Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {Link} from "@material-ui/core";
import dayjs from "dayjs";
import {mapCountryIcon} from "../../utils/Helper";
import {useHistory} from "react-router-dom";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";

export const LTLQuoteListEach = ({quoteInfo}) => {

    const history = useHistory();

    console.log('[LTLQuoteListEach] quoteInfo', quoteInfo);

    return (
        <Fragment>
            <Grid item md={4} xs={4}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={6}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {quoteInfo?.ship_from_city}, {quoteInfo?.ship_from_province}, {quoteInfo?.ship_from_country}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {quoteInfo?.ship_to_city}, {quoteInfo?.ship_to_province}, {quoteInfo?.ship_to_country}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={3} xs={3}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Grid container spacing={1}>
                        <Grid item md={4} xs={4}>
                            {
                                quoteInfo?.icon_uri ?
                                    <img src={`${LABEL_DOWNLOAD_URL_PREFIX}${quoteInfo?.icon_uri}`} alt="logo"
                                         width='35px'/>
                                    :
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}>
                                        <Typography style={{fontSize: '14px'}}>
                                            {quoteInfo?.account_base}
                                        </Typography>
                                    </Box>
                            }
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    $ {(+quoteInfo?.final_amount)?.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={4}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {quoteInfo?.total_weight_lb} / {(+quoteInfo?.ftl_count) || (+quoteInfo?.quantity)} {(+quoteInfo?.ftl_count) ? 'FTL' : 'Skids'}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/*<Grid item md={2} xs={2}>*/}
            {/*    <Grid container spacing={1}>*/}
            {/*        <Grid item md={1} xs={1}>*/}
            {/*            <Box sx={{*/}
            {/*                display: 'flex',*/}
            {/*                justifyContent: 'center',*/}
            {/*                alignItems: 'center',*/}
            {/*                height: '100%',*/}
            {/*            }}>*/}
            {/*                <Typography style={{fontSize: '14px'}}>*/}
            {/*                    {quoteInfo?.total_weight_lb || 'N/A'}*/}
            {/*                </Typography>*/}
            {/*            </Box>*/}
            {/*        </Grid>*/}

            {/*    </Grid>*/}
            {/*</Grid>*/}
            <Grid item md={5} xs={5}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Grid container spacing={1}>
                        <Grid item md={4} xs={4}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {quoteInfo?.quote_no || 'N/A'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={3}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {dayjs(quoteInfo?.expired_time).format("YYYY-MM-DD")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={2} xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    textAlign: "left",
                                    color: (+quoteInfo?.status) === 1 ? "#1D8B45" : "#454545"
                                }}>
                                    {(+quoteInfo?.status) === 1 ? "Active" : "Inactive"}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={3}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => history.push(`/v2/ltl-quote-list/${quoteInfo?.id}`)}
                                >
                                    <Typography style={{
                                        fontSize: '14px',
                                        textTransform: 'none',
                                        color: '#1D8B45'
                                    }}>
                                        Details
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )
}