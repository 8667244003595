import {Container, useMediaQuery} from "@mui/material";
import {LTLQuoteList} from "./LTLQuoteList";
import {LTLQuickQuote} from "./LTLQuickQuote";
import Box from "@mui/material/Box";
import {FormLabel, Grid, Typography} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Checkbox from "@mui/material/Checkbox";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {LTLQuoteAdd} from "./LTLQuoteAdd";

const styles = {
    ShippingLTLQuickQuoteSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    ShippingLTLQuickQuoteSection2nd: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    ShippingPackageQuickQuoteHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

export const LTLQuote = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const isMobile = useMediaQuery('(max-width: 600px)');
    const [type, setType] = useState('quote');

    const handleChangeType = (value) => {
        setType(value);
    }

    useEffect(() => {
        const queryObject = {};
        for (let [key, value] of searchParams.entries()) {
            queryObject[key] = value;
        }
        setType(queryObject?.type || 'quote');
    }, [])

    console.log('[LTLQuote] searchParams', searchParams);

    return (
        <Container maxWidth="xl">
            <Box sx={styles.ShippingLTLQuickQuoteSection}>
                <Typography style={styles.ShippingPackageQuickQuoteHeading}>
                </Typography>
                <Box
                    sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'quote'}
                            onClick={() => handleChangeType('quote')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            LTL Quick Quote
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'list'}
                            onClick={() => handleChangeType('list')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            LTL Quote List
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'add'}
                            onClick={() => handleChangeType('add')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Add Quote
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {
                type === 'list' ? <LTLQuoteList/> :
                    type === 'quote' ? <LTLQuickQuote/> : <LTLQuoteAdd handleChangeType={handleChangeType}/>
            }
            {/*{*/}
            {/*    type === 'list' ? <LTLQuoteList/> : <LTLQuickQuote/>*/}
            {/*}*/}
        </Container>
    )
}