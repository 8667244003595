import {Fragment, useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {FormHelperText, FormLabel, Grid, MenuItem, Select, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal} from "../../utils/customComponent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export const CarQuoteInsurance = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [description, setDescription] = useState('include 2');
    const [includeInsurance, setIncludeInsurance] = useState(false);

    const onChange = (field, value) => {
        switch (field) {
            case 'description':
                setDescription(value);
                break;
            default:
        }
    }

    const handleIncludeInsurance = () => {
        setIncludeInsurance(!includeInsurance);
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                carInsurance: {
                    description,
                    includeInsurance
                }
            });
        }
    }, [triggerAction])

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Insurance
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={includeInsurance}
                                        onChange={handleIncludeInsurance}
                                    />}
                                    label={
                                        <Typography>
                                            Include Insurance
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                    disabled={isConfirm}
                                />
                            </Box>
                        </Grid>
                        {
                            includeInsurance &&
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel sx={{textAlign: 'left'}}>
                                        Description
                                    </FormLabel>
                                    <CustomTextFieldNormal
                                        defaultValue={description}
                                        field='description'
                                        onChange={onChange}
                                        isConfirm={isConfirm}
                                    />
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>

    )
}
