import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import {CSVLink} from "react-csv";
import {getAccessToken} from "../../utils/doToken";
import {useEffect, useRef, useState} from "react";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {separateTaxes} from "../../utils/Helper";
import {saveAs} from 'file-saver';
import {triggerBase64Download} from "common-base64-downloader-react";

const style = {
    PackageOrderDashboardDocumentsRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        background: '#FFFFFF'
    }
}

export const PackageOrderTransactionDocuments = ({orderInfo}) => {

    const storedToken = getAccessToken("access_token");

    const [invoiceDetail, setInvoiceDetail] = useState(null);
    const [invoiceLoading, setInvoiceLoading] = useState(true);
    const [transactions, setTransactions] = useState([
        [
            "Order ID",
            "Cargo Control Number",
            "Ref Order No",
            "Tran ID",
            "Account Base",
            "Client Number",
            "Product ID",
            "Exclude Tax",
            "Include Tax",
            "GST",
            "HST",
            "QST",
            "Total Brokerage Charge",
            "Total Duties Charge",
            "Total Duties Tax Charge",
            "Service Fee Exclude Tax",
            "Service Fee Include Tax",
            "Shipping Total Tax",

            "Freight Charge",
            "SurCharge",
            "Fuel Charge",
            "Collect Charge",
            "Residential Charge",
            "Insurance Charge",
            "Rural Charge",
            "Extended Area Charge",
            "Extra Care Charge",
            "Handling Charge",
            "Over Size Charge",
            "Over Weight Charge",
            "COD Charge",
            "COS Charge",
            "Dangerous Charge",
            "Surcharge Details",
            "Date",
            "Tracking #",
            "Labels",

            "Send From Name",
            // "Send From Company",
            "Send From Address",
            "Send From City",
            "Send From Province",
            "Send From Country",
            "Send From Postal Code",

            "Send To Name",
            // "Send To Company",
            "Send To Phone",
            "Send To Email",
            "Send To Address",
            "Send To City",
            "Send To Province",
            "Send To Country",
            "Send To Postal Code"
        ],
    ]);
    const [trackings, setTrackings] = useState([
        ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
    ])
    const csvLink = useRef();
    const csvTrackingLink = useRef();

    const handleDownUploadedFile = () => {
        const fileURL = `${LABEL_DOWNLOAD_URL_PREFIX}${orderInfo?.origi_shipping_item_file}`;
        // const fileURL = 'https://storage.googleapis.com/uucargo-resource/tmp/upload/shipping/1714-shipping-package-list-sample_13_.xlsx';
        saveAs(fileURL, 'downloaded-file.xlsx');
    }

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const downloadLabelIntoZip = async () => {

        const requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/getLabelZipByOrderId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                responseType: "blob",
                params: {
                    orderId: orderInfo?.order_id
                }
            })

            const data = result?.data;

            const blobData = new Blob([data], {
                type: "application/zip"
            });
            saveAs(blobData, `${orderInfo?.order_id}.zip`);
            // console.log("labels: ", labels);
            // saveZip("my_project_files_to_download.zip", labels);
        } catch (e) {
            console.log(e);
        }
    }

    // get transaction data and download it
    const getTransactionData = async () => {

        const requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/getTransactionsByOrderId`;
        const resultArray = [
            [
                "Order ID",
                "Cargo Control Number",
                "Ref Order No",
                "Tran ID",
                "Account Base",
                "Client Number",
                "Product ID",
                "Exclude Tax",
                "Include Tax",
                "GST",
                "HST",
                "QST",
                "Total Brokerage Charge",
                "Total Duties Charge",
                "Total Duties Tax Charge",
                "Service Fee Exclude Tax",
                "Service Fee Include Tax",
                "Shipping Total Tax",

                "Freight Charge",
                "SurCharge",
                "Fuel Charge",
                "Collect Charge",
                "Residential Charge",
                "Insurance Charge",
                "Rural Charge",
                "Extended Area Charge",
                "Extra Care Charge",
                "Handling Charge",
                "Over Size Charge",
                "Over Weight Charge",
                "COD Charge",
                "COS Charge",
                "Dangerous Charge",
                "Surcharge Details",
                "Date",
                "Tracking #",
                "Labels",

                "Send From Name",
                // "Send From Company",
                "Send From Address",
                "Send From City",
                "Send From Province",
                "Send From Country",
                "Send From Postal Code",

                "Send To Name",
                // "Send To Company",
                "Send To Phone",
                "Send To Email",
                "Send To Address",
                "Send To City",
                "Send To Province",
                "Send To Country",
                "Send To Postal Code"
            ],
        ];

        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    orderId: orderInfo?.order_id
                }
            })
            // console.log('[getTransactionData] result', result);
            const apiResult = result?.data?.result;
            for (const column of apiResult) {
                const columnData = [];
                columnData.push(column.order_id);
                columnData.push(column.cargo_control_no);
                columnData.push(column.ref_order_no);
                columnData.push(column.tran_id);
                columnData.push(column.account_base);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                // Swap Price
                columnData.push(column.amount_gross);
                columnData.push(column.amount);

                const taxesObject = separateTaxes({
                    excludeTaxAmount: column.amount_gross,
                    includeTaxAmount: column.amount
                })
                // // GST
                // columnData.push(taxesObject.gstTax);
                // // PST
                // columnData.push(taxesObject.pstTax);
                // columnData.push(taxesObject["GST/HST"]);
                columnData.push(taxesObject.gstTax);
                columnData.push(taxesObject.hstTax);
                columnData.push(taxesObject.qstTax);

                columnData.push(column.total_brokerage_charge);
                columnData.push(column.total_duties_charge);
                columnData.push(column.total_duties_taxes_charge);
                columnData.push(column.service_fee_exclude_tax);
                columnData.push(column.service_fee_include_gst);
                columnData.push(column.shipping_total_tax);

                columnData.push(column.freight_charge);
                columnData.push(column.sur_charge);
                columnData.push(column.fuel_surcharge);
                columnData.push(column.collect_charge);
                columnData.push(column.residential_charge);
                columnData.push(column.insurance_charge);
                columnData.push(column.rural_charge);
                columnData.push(column.extended_area_charge);
                columnData.push(column.extra_care_charge);
                columnData.push(column.handling_charge);
                columnData.push(column.over_size_charge);
                columnData.push(column.over_weight_charge);
                columnData.push(column.cod_charge);
                columnData.push(column.cos_charge);
                columnData.push(column.dangerous_charge);
                columnData.push(column?.surcharge_details || "");

                columnData.push(column.tranacted_at);
                columnData.push(column.tran_tracking_nos);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");

                // Send From Info
                columnData.push(column.send_from_name);
                columnData.push(column.send_from_addr);
                columnData.push(column.send_from_city);
                columnData.push(column.send_from_province);
                columnData.push(column.send_from_country);
                columnData.push(column.send_from_postal_code);

                // Send To Info
                columnData.push(column.send_to_name);
                columnData.push(column.send_to_tel);
                columnData.push(column.send_to_email);
                columnData.push(column.send_to_addr);
                columnData.push(column.send_to_city);
                columnData.push(column.send_to_province);
                columnData.push(column.send_to_country);
                columnData.push(column.send_to_postal_code);

                resultArray.push(columnData);
            }

            setTransactions(resultArray);
            csvLink.current.link.click()

        } catch (e) {
            console.log('error', e?.response);
        }

    }

    // get tracking data and download it
    const getTrackingData = async () => {

        const requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/trackings`;
        const resultArray = [
            ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
        ];
        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: {
                    orderId: orderInfo?.order_id
                }
            })
            // console.log('[getTransactionData] result', result);
            const apiResult = result?.data?.result;
            for (const column of apiResult) {
                const columnData = [];
                columnData.push(orderInfo?.order_id);
                columnData.push(orderInfo.ref_order_no);
                columnData.push(orderInfo.cargo_control_no);
                columnData.push(orderInfo.account_base);
                columnData.push(column.tracking_no);
                // columnData.push(column.total_amount);
                // columnData.push(column.amount);
                // columnData.push(column.currency_code);
                columnData.push(column.tran_id);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");
                columnData.push(column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A");
                columnData.push(column.tranacted_at);

                resultArray.push(columnData);
            }

            setTrackings(resultArray);
            csvTrackingLink.current.link.click()

        } catch (e) {
            console.log('error', e?.response);
        }

    }

    const getInvoiceNumber = async () => {
        setInvoiceLoading(true);
        const requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/getInvoiceInfoByInvoiceId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: orderInfo?.crm_invoice_no
                }
            })
            console.log('result', result);
            setInvoiceDetail(result?.data);
        } catch (e) {
            console.log('e', e?.response);
        } finally {
            setInvoiceLoading(false);
        }
    }

    const downloadPDFByInvoiceId = async () => {
        try {
            const requestURL = `${NODE_ROUTE_URI}/billingCenter/admin/downloadInvoice`;
            const {data} = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: orderInfo?.crm_invoice_no
                }
            })
            const content = data.content;
            const contentType = data["content-type"];
            const pdfContent = `data:${contentType};base64,${content}`;
            const fileName = data.filename?.length > 4 ? data.filename.slice(0, data.filename.length - 4) : data.filename;
            triggerBase64Download(pdfContent, fileName);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleDownInvoice = () => {
        downloadPDFByInvoiceId();
    }

    useEffect(() => {
        orderInfo?.crm_invoice_no ? getInvoiceNumber() : setInvoiceLoading(false);
    }, [orderInfo]);

    console.log('[PackageOrderTransactionDocuments] order info', orderInfo);

    return (
        <Box sx={style.PackageOrderDashboardDocumentsRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Shipping Documents
                        </Typography>
                    </Box>
                </Grid>
                {orderInfo?.origi_shipping_item_file &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    Uploaded File
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleDownUploadedFile}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    color: '#1D8B45',
                                }}>
                                    Download
                                </Typography>
                            </Button>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            Transaction Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant='outlined'
                        sx={{
                            border: '2px solid #1D8B45',
                            "&:hover": {
                                border: '2px solid #1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={getTransactionData}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            color: '#1D8B45',
                        }}>
                            Download
                        </Typography>
                    </Button>
                    <CSVLink
                        data={transactions}
                        filename={`${orderInfo?.order_id}-${orderInfo?.ref_order_no}-transactions.csv`}
                        className='hidden'
                        ref={csvLink}
                        target='_blank'
                    />
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            Tracking Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant='outlined'
                        sx={{
                            border: '2px solid #1D8B45',
                            "&:hover": {
                                border: '2px solid #1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={getTrackingData}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            color: '#1D8B45',
                        }}>
                            Download
                        </Typography>
                    </Button>
                    <CSVLink
                        data={trackings}
                        filename={`${orderInfo?.order_id}-${orderInfo?.ref_order_no}-trackings.csv`}
                        className='hidden'
                        ref={csvTrackingLink}
                        target='_blank'
                    />
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            Shipping Label
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant='outlined'
                        sx={{
                            border: '2px solid #1D8B45',
                            "&:hover": {
                                border: '2px solid #1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={downloadLabelIntoZip}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            color: '#1D8B45',
                        }}>
                            Download
                        </Typography>
                    </Button>
                </Grid>
                {
                    invoiceDetail &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    Invoice Number
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                textTransform: 'none',
                                color: '#B454545',
                            }}>
                                {invoiceDetail?.invoiceRefNumber}
                            </Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            Invoice Status
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        textTransform: 'none',
                        color: !orderInfo?.crm_invoice_no ? '#454545' : invoiceDetail?.status === "2" ? '#1D8B45' : '#FF0303',
                    }}>
                        {
                            !orderInfo?.crm_invoice_no ? "Not Billed" : invoiceDetail?.status === "2" ? "Paid" : "Unpaid"
                        }
                    </Typography>
                </Grid>
                {
                    invoiceDetail &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    Invoice Details
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleDownInvoice}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    color: '#1D8B45',
                                }}>
                                    Download
                                </Typography>
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    )
}